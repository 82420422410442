<template>
    <div>
        <!-- Main Layout -->
        <b-row no-gutters>
            <b-col v-if="this.metadataValues" cols="2" class="bg-light" style="padding: 0;">
                <div style="position: fixed; top: 0;  padding: 20px; padding-top: 80px; max-height: 100vh; overflow-y: auto;">
                    <div v-for="folder in folders" :key="folder.id" class="mt-2">
                        <FolderComponent :folder="folder" :vendors="vendors" :metadataValues="metadataValues" @filterField="handleFilterField"></FolderComponent>
                    </div>
                    <div class="mt-2" @click="$bvModal.show('multilevel-sort-modal')" style="cursor: pointer; display: inline-flex; align-items: center;">
                        <b-icon icon="gear"></b-icon>
                        <span class="ml-2">Folder Settings</span>
                    </div>
                </div>
            </b-col>
            <b-col v-else cols="2" class="bg-light d-flex align-items-center justify-content-center" style="padding: 0;">
                <div style="position: sticky; top: 0;  padding: 20px; max-height: 100vh; overflow-y: auto;">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
            </b-col>
            <b-col cols="10">
                <search-document :folders="folders" :vendors="vendors" :folderFilter="folderFilter" @metadataValues="handleMetadataValues"></search-document>
            </b-col>
        </b-row>

        <b-modal id="multilevel-sort-modal" :title="(selectedRoot?.name ? selectedRoot.name + ' ' : '') + 'Folder Settings'" @hide="hideModal">
            <!-- Select Root Folder -->
            <b-row v-if="!selectedRoot">
                <b-col cols="12" class="mt-2">
                    <multiselect 
                        v-model="selectedRoot" 
                        :options="folders" 
                        label="name" 
                        track-by="id" 
                        placeholder="Select a root folder"
                        @input="handleSubFolderSelection(selectedRoot, $event)">
                    </multiselect>
                </b-col>
            </b-row>

            <div v-if="selectedRoot">
    <b-row v-for="(folder, index) in getChildren(selectedRoot)" :key="index">
        <b-col cols="12" class="folderSettings">
            <b-row>
                <b-col cols="auto">
                    <b-form-checkbox v-model="folder.isVisible" inline></b-form-checkbox>
                </b-col>

                <b-col>
                    <label>{{ folder.name }}</label>
                </b-col>
            </b-row>

            <!-- Allow selecting siblings as children -->
            <multiselect 
                v-model="folder.children" 
                :options="getSiblings(folder)" 
                label="name" 
                track-by="id" 
                multiple
                placeholder="Select sub-folder/s"
                @input="handleSubFolderSelection(folder, $event)">
            </multiselect>
        </b-col>
    </b-row>
</div>

            <hr class="mx-3">

            <b-row>
                <b-col>
                    <div class="d-flex justify-content-end">
                        <div>
                            <b-button variant="outline-red" squared class="ml-2" @click="hideModal">Cancel</b-button>
                        </div>
                        <div>
                            <b-button variant="primary" squared class="ml-2" @click="saveFolderStructure">Save</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>

    <button @click="scrollToTop" class="scroll-to-top-btn">
      ↑ Go To Top
    </button>
    </div>
</template>
  
  <script>
  import FolderComponent from "./FolderComponent.vue";
  import SearchDocument from "./SearchDocument.vue";
  import Multiselect from 'vue-multiselect'
  import warehouseApi from '../warehouseApi';
  import api from '../api';
  import { v4 as uuidv4 } from 'uuid';
  import eventBus from '../eventBus';

  export default {
      components: {
          FolderComponent,
          SearchDocument,
          Multiselect
      },
      beforeMount() {
            Promise.all([this.getUserFolderStructure()])
            .then(() => {
                this.getVendors();

                return  this.getDocumentTypes();
            })
            .catch(error => {
                console.error("An error occurred:", error);
            });
        },
    mounted() {
        eventBus.$on('folder-edit', this.handleFoldeEdit);
        eventBus.$on('folder-remove', this.handleFoldeRemove);
    },
    beforeDestroy() {
        eventBus.$off('folder-edit', this.handleFoldeEdit);
        eventBus.$off('folder-remove', this.handleFoldeRemove);
    },
      data() {
        return {
            selectedRoot: null,
            userFolderId: null,
            vendors: [],
            folders: [],
            arrangeLevels: [ { selectedFolder: null, selectedFolders: [] } ],
            folderFilter: null,
            metadataValues: null,
            displayFolderStructure: false,
            role: null
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        handleFoldeEdit(folder){
            let rootFolder = this.folders.find(x => x.name === folder.parentName);
            if (rootFolder != null) {
                this.selectedRoot = rootFolder;
            } else {
                this.selectedRoot = folder;
            }

            this.$bvModal.show('multilevel-sort-modal');
        },
        handleFoldeRemove(folder){
            let rootFolder = this.folders.find(x => x.name === folder.parentName);

            function removeChildById(folder, idToRemove) {
                if (!folder.children) {
                    return false;
                }

                const index = folder.children.findIndex(child => child.id === idToRemove);

                if (index > -1) {
                    folder.children.splice(index, 1);
                    return true;
                }

                for (let child of folder.children) {
                    const removed = removeChildById(child, idToRemove);
                    if (removed) {
                    return true;
                    }
                }

                return false;
            }

            removeChildById(rootFolder, folder.id);
        },
        handleSubFolderSelection(folder, selectedFolders) {
            if(folder.isRootFolder){
                folder.showSubfolders = true;
            } else {
                folder.showSubfolders = false;

                if(selectedFolders.some(x => x.id === folder.id)) {
                    folder.isVisible = !folder.isVisible;
                }
            }
        },
        handleMetadataValues(metadataValues){
            this.metadataValues = metadataValues;
            this.displayFolderStructure = true;
        },
        handleFilterField(folderFilter){
            this.folderFilter = folderFilter;
        },
        getVendors(){
            api.GetVendors(data =>
                {
                    this.vendors = data.slice().sort((a, b) => a.name.localeCompare(b.name));
                    this.vendors = this.vendors.map(vendor => ({name: vendor.name, id: vendor.id}));
                    // this.vendors = this.vendors.map(vendor => vendor.name);
                },
            console.error)
        },
        getChildren(rootFolder) {
            return rootFolder ? rootFolder.children : [];
        },
        hasFolderInHierarchy(targetFolder, searchFolder) {
            if (searchFolder.id === targetFolder.id) return true;

            for (const child of searchFolder.children) {
                if (this.hasFolderInHierarchy(targetFolder, child)) {
                    return true;
                }
            }
            return false;
        },
        mapFolderStructure(originalFolders, newDataArray) {
            for (const newData of newDataArray) {
                const index = originalFolders.findIndex(folder => folder.name === newData.name);
                
                const uniqueID = uuidv4();
                const newFolder = {
                    id: uniqueID,
                    name: newData.name,
                    showSubfolders: false,
                    isRootFolder: true,
                    children: newData.metadata.map(meta => ({
                        id: meta.id,
                        name: meta.fieldName,
                        fieldType: meta.fieldType,
                        showSubfolders: false,
                        isVisible: false,
                        children: [],
                        isRootFolder: false,
                        parentName: newData.name
                    }))
                };

                if (index !== -1) {
                    originalFolders[index] = newFolder;
                } else {
                    originalFolders.push(newFolder);
                }
            }

            return originalFolders;
        },
        getUserFolderStructure() {
            warehouseApi.getUserFolderStructure()
                .then((data) => {
                    this.folders = JSON.parse(data.folderStructure);
                    this.userFolderId = data.id;
                })
                .catch(() => {
                    // this.saveFolderStructure();
                });
        },
        getDocumentTypes() {
            warehouseApi.getDocumentTypes()
                .then((data) => {
                this.documentTypes = data;
                if(this.folders.length === 0) {
                    this.mapFolderStructure(this.folders, this.documentTypes);
                }
                // this.mapFolderStructure(this.folders, this.documentTypes);
                })
                .catch((error) => {
                console.error(error);
                });
        },
        getSiblings(folder) {
            if (!this.selectedRoot) return [];

            return this.selectedRoot.children.filter(f => {
                // Remove the folder itself from the list of siblings.
                if (f.id === folder.id) return false;

                // Check if the current sibling has the folder anywhere in its hierarchy.
                const hasFolderInHierarchy = this.hasFolderInHierarchy(folder, f);

                // Return false (remove from the list) if it does.
                return !hasFolderInHierarchy;
            });
        },
        getAvailableFoldersForLevel(index) {
            let previouslySelected = this.arrangeLevels.slice(0, index).map(level => level.selectedFolder.id);
            return this.folders.filter(folder => !previouslySelected.includes(folder.id));
        },
        addArrangeLevel() {
            if(this.arrangeLevels.length < this.folders.length) {
                this.arrangeLevels.push({ selectedFolder: null });
            }
        },
        saveFolderStructure() {
            let request = {
                user: {
                    id: 0,
                    role: "Admin"
                },
                folderStructure: JSON.stringify(this.folders)
            };

            warehouseApi.createFolderStructure(request)
                .then((data) => {
                    this.userFolderId = data.id;
                    this.hideModal();
                    this.$toast.success('Folder settings saved successfully!');
                }) 
                .catch((error) => {
                    this.$toast.error('Folder settings could not be saved!');
                    console.error(error);
                });
        },
        hideModal() {
            this.selectedRoot = null;
            this.$bvModal.hide('multilevel-sort-modal');
        }
    }
  };
  </script>
  
  <style scoped>
    @import "~vue-multiselect/dist/vue-multiselect.min.css";

    .folderSettings .col-auto, .folderSettings .col {
        padding-left: 0px;
        padding-right: 0px;
    }

    .folderSettings  .custom-control-inline {
        margin-right: 0px;
    }

    .folderSettings {
        margin-top: 15px;
    }
    
    
    .scroll-to-top-btn {
        position: fixed;
        bottom: 10px;
        right: 20px;
        padding: 5px 10px;
        font-size: 16px;
        color: #fff;
        background-color: #060f1a;
        border: none;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        transition: background-color 0.3s, box-shadow 0.3s;
    }

    .scroll-to-top-btn:hover {
        background-color:  #040c14;
        box-shadow: 0 5px 15px rgba(0,0,0,0.4);
    }

    .scroll-to-top-btn:focus {
        outline: none;
    }
  </style>  