<template>
  <div>
    <b-row>
        <b-row class="h-100" v-if="state === 'loading'" align-h="center">
          <b-spinner label="Loading..."></b-spinner>
        </b-row>
        <iframe v-if="state === 'show' && url" :src="url" style="width: 100%; height: 500px;"></iframe>
        <div v-if="state === 'show'" style="width: 100%;">
          <b-row>
            <b-col cols="4">
              <label>Document Type</label>
              <b-form-select v-model="selectedDocumentType" :disabled="true">
                <option v-for="documentType in documentTypes" :value="documentType" :key="documentType.value">
                  {{ documentType.name }}
                </option>
              </b-form-select>
            </b-col>
            <b-col cols="4">
              <label>Name</label>
              <b-input-group>
                <b-form-input v-model="documentName" :disabled="isReadOnly"></b-form-input>
                <!-- <b-input-group-append>
                  <b-button @click="downloadDocument" :disabled="isDocumentDownload"><b-icon-eye></b-icon-eye></b-button>
                </b-input-group-append> -->
              </b-input-group>
            </b-col>
            <!-- <b-col cols="3">
              <label>Select Document</label>
              <b-form-file v-model="file" :disabled="isReadOnly || isDocumentDownload"></b-form-file>
            </b-col> -->
            <b-col cols="4">
              <label>Created By</label>
              <b-form-input v-model="uploadBy" :disabled="true"></b-form-input>
            </b-col>
          </b-row>
          <!-- <b-row v-if="selectedDocumentType" class="mb-3">
                <b-col cols="12" class="mb-2 mt-2">
                  <h4 style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">{{selectedDocumentType.name}} Fields</h4>
                </b-col>
                <b-col cols="12">
                  <b-form-checkbox-group :disabled="isReadOnly">
                  <b-form-checkbox  v-for="field in selectedDocumentType.metadata" :key="field.fieldName" v-model="selectedDocumentTypeMetadataList" :value="field.fieldName">{{ field.fieldName }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-col >
            </b-row> -->
            <div v-if="selectedMetadataList.length > 0">
              <b-row>
                <b-col cols="12" class="mb-2 mt-2">
                    <h4 style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">Fields</h4>
                </b-col>
              </b-row>
              <b-row v-for="(groupedMetadata, metadataRowIndex) in selectedMetadataList" :key="metadataRowIndex + '_metadataRowIndex'">
                <b-col cols="4" v-for="(metadataName, metadataColIndex) in groupedMetadata" :key="metadataColIndex + 'metadataColIndex'">
                  <label>{{ metadataName }}</label>
                  <template v-if="getFieldType(metadataName) === 'vendor' || getFieldType(metadataName) === 'list'">
                    <b-form-input v-model="inputValues[metadataName]" :disabled="isReadOnly"></b-form-input>
                  </template>
                  <template v-if="getFieldType(metadataName) === 'default'">
                    <b-form-input v-model="inputValues[metadataName]" :disabled="isReadOnly"></b-form-input>
                  </template>
                  <template v-else-if="getFieldType(metadataName) === 'number'">
                    <b-form-input type="number" v-model="inputValues[metadataName]" :disabled="isReadOnly"></b-form-input>
                  </template>
                  <template v-else-if="getFieldType(metadataName) === 'date'">
                    <b-form-datepicker v-model="inputValues[metadataName]" :disabled="isReadOnly"></b-form-datepicker>
                  </template>
                  <template v-else-if="getFieldType(metadataName) === 'boolean'">
                    <b-form-checkbox v-model="inputValues[metadataName]" :disabled="isReadOnly">{{ metadataName }}</b-form-checkbox>
                  </template>
                </b-col>
              </b-row>
            </div>
          <hr class="mx-3">
          <b-row>
            <b-col>
              <div class="d-flex justify-content-end">
                <div>
                  <b-button variant="outline-red" squared class="ml-2" @click="cancel">Cancel</b-button>
                </div>
                <div v-if="!isReadOnly">
                  <b-button variant="primary" squared class="ml-2" @click="saveDocument" :disabled="isDocumentDownload">Save
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
    </b-row>
  </div>
</template>
<script>
import {
  mapMutations,
  mapState
} from "vuex";
import warehouseApi from '../warehouseApi'
import moment from 'moment';

export default {
  name: "ViewDocument",
  data: () => ({
    iconSelected: "",
    file: null,
    documentName: null,
    uploadBy: null,
    selectedFile: {
      name: "",
      type: "",
      content: ""
    },
    state: 'show',
    url: "",
    selectedDocumentType: null,
    selectedDocumentTypeMetadataList: [],
    inputValues: {},
    selectedMetadataList: [],
    displayMetadata: [],
    documentTypes: [],
    isDocumentDownload: false,
    isReadOnly: false,
    documentId: 0
  }),
  props: {
    entryId: Number
  },
  created() {
    this.getDocumentTypes();
  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    getDocument(){
      warehouseApi.getDocument(this.entryId)
        .then((data) => {
          this.documentId = data.id;
          this.documentName = data.name;
          this.uploadBy = data.uploadBy;
          this.selectedDocumentType = this.documentTypes.find(document => document.name === data.documentType.name);
          this.inputValues = this.getObjectFromStructuredMetadata(data.metadata);
          this.selectedDocumentTypeMetadataList = data.metadata.map(item => item.fieldName);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFieldType(fieldName) {
      const result = this.selectedDocumentType.metadata.find(item => item.fieldName === fieldName);
      return result ? result.fieldType.toLowerCase() : null;
    },
    getField(fieldName) {
      const result = this.selectedDocumentType.metadata.find(item => item.fieldName === fieldName);
      return result ? result : null;
    },
    getDocumentTypes() {
      warehouseApi.getDocumentTypes()
        .then((data) => {
          this.documentTypes = data;
          this.getDocument();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStructuredMetadata(){
      // const result = this.selectedDocumentType.metadata.find(item => item.fieldName === fieldName);
      console.log(this.selectedDocumentType.metadata);
      return Object.keys(this.inputValues).map(key => ({
                    field: this.getField(key),
                    id: this.getField(key).id,
                    value: this.inputValues[key]
                  }));
    },
    formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    },
    getObjectFromStructuredMetadata(structuredMetadata) {
      const result = {};

      structuredMetadata.forEach(item => {
        let value = item.fieldValue;

        if (item.fieldName.toLowerCase().includes("date")) {
            value = this.formatDate(value);
        }
        result[item.fieldName] = value;
      });
      return result;
    },
    doDownloadDocument(data) {
      const documentName = data.documentName;
      const base64Content = data.documentContent;

      const blob = this.b64toBlob(base64Content);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const url = window.URL.createObjectURL(blob);

      a.download = documentName;

      a.href = url;
      a.click();

      window.URL.revokeObjectURL(url);
      this.isDocumentDownload = false;
    },
    b64toBlob(base64Data) {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray]);
    },
    downloadDocument() {
      this.isDocumentDownload = true;
      warehouseApi.getDocumentWithContent(this.entryId)
        .then((data) => {
          this.url = `data:application/pdf;base64,${data.documentContent}`;
        })
        .catch((error) => {
          this.isDocumentDownload = false;
          console.log(error);
        });
    },
    saveDocument(){
      const metadata = this.getStructuredMetadata();
  
      const extension = this.selectedFile.name.split('.')[1];
      const documentRequest = {
        id: this.entryId,
        name: this.documentName,
        extension: extension,
        mimeType: this.selectedFile.type,
        content: this.selectedFile.content,
        metadata: metadata,
        documentType: this.selectedDocumentType
      };

      warehouseApi.UpdateDocument(documentRequest)
        .then((data) => {
          console.log("Document Updated:", data);
          this.state = "cancelled";
          this.$emit('toggleViewDocument');
          this.$toast.success('Document updated successfully!');
        })
        .catch((error) => {
          this.$toast.success('Could not update document!');
          console.error(error);
        });
    },
    cancel() {
      this.state = "cancelled";
      this.$emit('toggleViewDocument');
    }
  },
  computed: {
    ...mapState([
      'SelectedApplicationAndService'
    ])
  },
  watch: {
    selectedDocumentTypeMetadataList(selectedList) {
      const groups = [];
      let group = [];

      for (let i = 0; i < selectedList.length; i++) {
          group.push(selectedList[i]);

          if (group.length === 3) {
              groups.push(group);
              group = [];
          }
      }

      // If there are any remaining elements not grouped
      if (group.length > 0) {
          groups.push(group);
      }

      this.selectedMetadataList = groups;

      for (const key in this.inputValues) {
        if (!selectedList.includes(key)) {
          delete this.inputValues[key];
        }
      }
    },
    file(newFile) {
      if (newFile) {
        const reader = new FileReader();
        reader.readAsDataURL(newFile);
        reader.onload = () => {
          this.selectedFile.name = newFile.name;
          this.selectedFile.type = newFile.type;
          this.selectedFile.content = reader.result.split(',')[1];
        };
      }
    }
  }
}
</script>
<style scoped></style>