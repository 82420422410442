<template>
    <div>
        <div @click="toggleMainFolder" @contextmenu.prevent="handleRightClick($event)" ref="folderElement" class="folder-link">
            <b-icon :icon="folder.showSubfolders ? 'folder2-open' : 'folder'" size="lg" class="folder-icon"></b-icon>
            <span>{{ folder.name }}</span>
        </div>
        <div v-if="showContextMenu" :style="{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }" class="context-menu" ref="contextMenu">
            <ul>
                <li class="d-flex justify-content-between align-items-center"> <b>{{ folder.name }} Settings</b> <b-icon-close></b-icon-close></li>
                <hr class="m-0">
                <li @click="performAction('Edit')" class="d-flex justify-content-between align-items-center">
                    <span>Edit</span>
                    <b-icon-pencil></b-icon-pencil>
                </li>
                <li v-if="!folder.isRootFolder" @click="performAction('Remove')" class="d-flex justify-content-between align-items-center">
                    <span>Remove</span>
                    <b-icon-trash></b-icon-trash>
                </li>
            </ul>
        </div>

        <div v-if="folder.fieldType && folder.showSubfolders">
            <div v-for="item in paginatedItems" :key="item.id">
                <div @click="toggleSubfoldersForItem(item, folder.name)" class="folder-link" style="margin-left:20px;">
                    <b-icon v-if="folder.children.length > 0" :icon="item.showSubfolders ? 'folder2-open' : 'folder'" size="lg" class="folder-icon"></b-icon>
                    <b-icon v-if="folder.children.length == 0" icon="folder-symlink" size="lg" class="folder-icon"></b-icon>
                    <span>{{ item.name }}</span> 
                </div>
                <div class="subfolder" style="margin-left:20px;">
                    <div v-for="child in folder.children" :key="child.id" class="subfolder">
                        <FolderComponent :folder="child" :metadataValues="metadataValues" @filterField="handleFilterField"></FolderComponent>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="folder.showSubfolders" class="subfolder">
            <div v-for="child in folder.children" :key="child.id" class="subfolder">
                <FolderComponent v-if="child.isVisible" :folder="child" :metadataValues="metadataValues" @filterField="handleFilterField"></FolderComponent>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import eventBus from '../eventBus';

export default {
    name: "FolderComponent",
    props: {
        folder: Object,
        vendors: Array,
        metadataValues: Object
    },
    mounted() {
        document.addEventListener('click', this.handleOutsideClick);
        eventBus.$on('folder-right-clicked', this.handleFolderRightClicked);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleOutsideClick);
        eventBus.$off('folder-right-clicked', this.handleFolderRightClicked);
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 25,
            showSubfoldersFor: {},
            searchTerm: '',
            filteredItems: [],
            selectedFolders: {},
            updateSubFolderId: null,
            showContextMenu: false,
            menuPosition: { x: 0, y: 0 }
        };
    },
    computed: {
        paginatedItems() {
            if (this.filteredItems) {
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return this.filteredItems.slice(start, end);
            }
            return [];
        },
        totalPages() {
            return Math.ceil(this.filteredItems.length / this.itemsPerPage);
        }
    },
    watch: {
        searchTerm() {
            this.currentPage = 1;
        },
        metadataValues() {
            if (this.folder.showSubfolders && this.folder.id == this.updateSubFolderId) {
                this.filteredItems = this.getItemsForFieldType(this.folder.name).filter(item => item.name.toLowerCase().includes(this.searchTerm.trim().toLowerCase()));
                this.updateSubFolderId = null;
            }
        }
    },
    components: {
        FolderComponent: () => import('./FolderComponent.vue')
    },
    created(){
        this.filteredItems = this.getItemsForFieldType(this.folder.name).filter(item => item.name.toLowerCase().includes(this.searchTerm.trim().toLowerCase()));
    },
    methods: {
        handleOutsideClick(event) {
            if (this.showContextMenu && (!this.$refs.folderElement.contains(event.target) && !this.$refs.contextMenu?.contains(event.target))) {
                this.showContextMenu = false;
            }
        },
        handleRightClick(event) {
            this.menuPosition.x = 50;
            this.menuPosition.y = event.layerY + 20;
            this.showContextMenu = true;
            eventBus.$emit('folder-right-clicked', this.folder.id);

            event.preventDefault();
        },
        handleFolderRightClicked(folderId) {
            if (this.folder.id !== folderId) {
                this.showContextMenu = false;
            }
        },
        performAction(action) {
            if(action == "Edit") {
                eventBus.$emit('folder-edit', this.folder);
                console.log("Action performed:", action);
            } else if (action == "Remove") {
                console.log(this.folder);
                eventBus.$emit('folder-remove', this.folder);
            }

            this.showContextMenu = false;
        },
        toggleMainFolder() {
            if (this.folder?.isRootFolder && !this.folder.children.some(child => child.isVisible)) {
                this.$toast.warning('Please set folder settings for ' +  this.folder.name);
            }
            else {
                this.folder.showSubfolders = !this.folder.showSubfolders;
                this.updateSubFolderId = this.folder.id;

                if (this.folder.showSubfolders) {
                    this.$emit('filterField', {filterType: "folder", folder: this.folder})
                }
            }
        },
        toggleSubfoldersForItem(item, fieldType) {
            let value = item.name;
            if (fieldType.toLowerCase().includes("date")) {
                value = this.formatDate(value);
            }

            this.$emit('filterField', {filterType: "value", fieldValue: value, fieldType: fieldType, parent: this.folder})
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        handleFilterField(folder){
            this.$emit('filterField', folder)
        },
        getItemsForFieldType(fieldType) {
            if (this?.metadataValues && fieldType && this.metadataValues[this.folder?.parentName] != undefined) {                
                return this.metadataValues[this.folder?.parentName][fieldType];
            }
            return [];
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        }
    }
};
</script>

<style scoped>
    .folder-link {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .folder-icon {
        margin-right: 10px;
    }
    .subfolder {
        margin-top: 5px;
        padding-left: 10px;
    }
    .subfolder-draggable {
        margin: 5px 0;
    }
    .vendor-folder {
        margin-top: 10px;
        padding: 5px;
    }
    .vendor-name {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .pagination-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }
    button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    .search-container {
        position: relative;
        margin: 10px 0;
    }
    .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    input {
        padding-left: 30px;
    }
    .highlighted {
        /* background-color: #edb800; */
        color: rgb(0, 0, 0);
        text-decoration: underline;
        text-decoration-color: #edb800;
    }


    .context-menu {
        position: absolute;
        border: 1px solid #ccc;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        z-index: 1000;
    }

    .context-menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .context-menu ul li {
        padding: 8px 12px;
        cursor: pointer;
    }

    .context-menu ul li:hover {
        background-color: #f5f5f5;
    }


</style>
