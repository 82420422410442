<template>
  <div>
    <b-row>
      <b-row class="h-100" v-if="state === 'loading'" align-h="center">
        <b-spinner label="Loading..."></b-spinner>
      </b-row>
      <div v-if="state === 'show'" style="width: 100%;">
        <b-row>
          <b-col cols="4">
            <label>Document Type <span class="text-danger">*</span></label>
            <b-form-select v-model="selectedDocumentType" :disabled="isSaving">
              <option v-for="documentType in documentTypes" :value="documentType" :key="documentType.value">
                {{ documentType.name }}
              </option>
            </b-form-select>
          </b-col>
          <b-col cols="4">
            <label>Name <span class="text-danger">*</span></label>
            <b-form-input v-model="documentName" :disabled="isSaving"></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>Select Document <span class="text-danger">*</span></label>
            <b-form-file v-model="file" :disabled="isSaving">
            </b-form-file>
          </b-col>
        </b-row>
        <b-row v-if="selectedDocumentType" class="mb-3">
              <b-col cols="12" class="mb-2 mt-2">
                <h4 style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">{{selectedDocumentType.name}} Fields</h4>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox-group :disabled="isSaving">
                <b-form-checkbox  v-for="field in selectedDocumentType.metadata" :key="field.fieldName" v-model="selectedDocumentTypeMetadataList" :value="field.fieldName">{{ field.fieldName }}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-col >
          </b-row>
          <div v-if="selectedMetadataList.length > 0">
            <b-row>
              <b-col cols="12" class="mb-2 mt-2">
                  <h4 style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">Please Complete Fields</h4>
              </b-col>
            </b-row>
            <b-row v-for="(groupedMetadata, metadataRowIndex) in selectedMetadataList" :key="metadataRowIndex + '_metadataRowIndex'">
              <b-col cols="4" v-for="(metadataName, metadataColIndex) in groupedMetadata" :key="metadataColIndex + 'metadataColIndex'">
                <label>{{ metadataName }} <span class="text-danger">*</span></label>
                <template v-if="getFieldType(metadataName) === 'default'">
                  <b-form-input v-model="inputValues[metadataName]" :disabled="isSaving"></b-form-input>
                </template>
                <template v-else-if="getFieldType(metadataName) === 'number'">
                  <b-form-input type="number" v-model="inputValues[metadataName]" :disabled="isSaving"></b-form-input>
                </template>
                <template v-else-if="getFieldType(metadataName) === 'date'">
                  <b-form-datepicker v-model="inputValues[metadataName]" :disabled="isSaving"></b-form-datepicker>
                </template>
                <template v-else-if="getFieldType(metadataName) === 'boolean'">
                  <b-form-checkbox v-model="inputValues[metadataName]" :disabled="isSaving">{{ metadataName }}</b-form-checkbox>
                </template>
                <template v-else-if="getFieldType(metadataName) === 'vendor'">
                  <b-form-select v-model="inputValues[metadataName]" :disabled="isSaving" searchable>
                    <option v-for="vendor in vendors" :key="vendor.id" :value="vendor.name">{{ vendor.name }}</option>
                  </b-form-select>
                </template>
              </b-col>
            </b-row>
          </div>
        <hr class="mx-3">
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <div>
                <b-button variant="outline-red" squared class="ml-2" @click="close" :disabled="isSaving">Cancel</b-button>
              </div>
              <div>
                <b-button variant="primary" squared class="ml-2" @click="saveDocument" :disabled="isSaving">{{ isSaving ? 'Saving...': "Save" }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>
<script>
import {
  mapMutations,
  mapState
} from "vuex";
import warehouseApi from '../warehouseApi';
import api from "../api";

export default {
  name: "applicationView",
  data: () => ({
    iconSelected: "",
    file: null,
    documentName: null,
    selectedFile: {
      name: "",
      type: "",
      content: ""
    },
    state: 'show',
    url: "",
    selectedDocumentType: null,
    selectedDocumentTypeMetadataList: [],
    inputValues: {},
    selectedMetadataList: [],
    displayMetadata: [],
    documentTypes: [],
    vendors: [],
    isSaving: false
  }),
  created() {
    this.getDocumentTypes();
    this.getVendors();
  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    getVendors(){
      api.GetVendors(data =>
      {
        this.vendors = data.slice().sort((a, b) => a.name.localeCompare(b.name));
        console.log(this.vendors);
      },
      console.error)
    },
    getFieldType(fieldName) {
      const result = this.selectedDocumentType.metadata.find(item => item.fieldName === fieldName);
      return result ? result.fieldType.toLowerCase() : null;
    },
    getField(fieldName) {
      const result = this.selectedDocumentType.metadata.find(item => item.fieldName === fieldName);
      return result;
    },
    getDocumentTypes() {
      warehouseApi.getDocumentTypes()
        .then((data) => {
          this.documentTypes = data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStructuredMetadata(){
      return Object.keys(this.inputValues).map(key => ({
                    value: this.inputValues[key],
                    field: this.getField(key)
                  }));
    },
    saveDocument(){
      this.isSaving = true;
      const metadata = this.getStructuredMetadata();
      const extension = this.selectedFile.name.split('.')[1];

      const documentRequest = {
        id: 0,
        name: `${this.documentName}.${extension}`,
        extension: extension,
        mimeType: this.selectedFile.type,
        content: this.selectedFile.content,
        metadata: metadata,
        documentType: this.selectedDocumentType
      };

      warehouseApi.createDocument(documentRequest)
        .then((data) => {
          this.isSaving = false;

          this.$emit('onDocumentSaved', data);
          this.close();

          if (this.selectedDocumentType && this.selectedDocumentType.name == "Load Confirmations") {
            const loadConfirmationNumber = documentRequest.metadata.find(item => item.field.fieldName === 'Load Confirmation Number')?.value;

            if (loadConfirmationNumber) {
              api.UpdateInvoiceStatus(loadConfirmationNumber, data?.entryId)
              .then((response) => {
                console.log(response)
              })
              .catch((error) => {
                console.error(error);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.isSaving = false;
        });
    },
    close() {
      this.state = "cancelled";
      this.$emit('toggleAddDocument');
    }
  },
  computed: {
    ...mapState([
      'SelectedApplicationAndService'
    ])
  },
  watch: {
    selectedDocumentTypeMetadataList(selectedList) {
      const groups = [];
      let group = [];

      for (let i = 0; i < selectedList.length; i++) {
          group.push(selectedList[i]);

          if (group.length === 3) {
              groups.push(group);
              group = [];
          }
      }

      // If there are any remaining elements not grouped
      if (group.length > 0) {
          groups.push(group);
      }

      this.selectedMetadataList = groups;

      for (const key in this.inputValues) {
        if (!selectedList.includes(key)) {
          delete this.inputValues[key];
        }
      }
    },
    file(newFile) {
      if (newFile) {
        const reader = new FileReader();
        reader.readAsDataURL(newFile);
        reader.onload = () => {
          this.selectedFile.name = newFile.name;
          this.selectedFile.type = newFile.type;
          this.selectedFile.content = reader.result.split(',')[1];
        };
      }
    }
  }
}
</script>
<style scoped></style>