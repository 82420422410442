<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Documents</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="toggleAddDocument">Add Document</b-button>
            </b-col>
          </b-row>
          <!-- <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row> -->
          <div>
            <b-row>
              <b-col cols="4">
                <label>Search </label>
                <b-form-input v-model="searchQuery"></b-form-input>
              </b-col>
              <b-col cols="4">
                <label>Date </label>
                <date-range-picker :defaultDate="defaultDate" ref="datePickerRef"
                           @onDateSelected="onDateSelected" />
              </b-col>
              <b-col cols="4">
                <label>Document Type</label>
                <b-form-select v-model="selectedDocumentType">
                  <option v-for="documentType in documentTypes" :value="documentType" :key="documentType.value">
                    {{ documentType.name }}
                  </option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row v-if="selectedDocumentType" class="mb-3">
                  <b-col cols="12" class="mb-2 mt-2">
                    <h4 style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">{{selectedDocumentType.name}} Search Fields</h4>
                  </b-col>
                  <b-col cols="12">
                    <b-form-checkbox-group>
                    <b-form-checkbox  v-for="field in selectedDocumentType.metadata" :key="field.fieldName" v-model="selectedDocumentTypeMetadataList" :value="field.fieldName">{{ field.fieldName }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col >
              </b-row>
              <div v-if="selectedMetadataList.length > 0">
                <b-row>
                  <b-col cols="12" class="mb-2 mt-2">
                      <h4 style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">Please Complete Fields</h4>
                  </b-col>
                </b-row>
                <!-- <b-row v-for="(groupedMetadata, metadataRowIndex) in selectedMetadataList" :key="metadataRowIndex + '_metadataRowIndex'">
                    <b-col cols="4" v-for="(metadataName, metadataColIndex) in groupedMetadata" :key="metadataColIndex + 'metadataColIndex'">
                      <label>{{ metadataName }}</label>
                      <b-form-input v-model="inputValues[metadataName]"></b-form-input>
                    </b-col>
                </b-row> -->

                <b-row v-for="(groupedMetadata, metadataRowIndex) in selectedMetadataList" :key="metadataRowIndex + '_metadataRowIndex'">
                  <b-col cols="4" v-for="(metadataName, metadataColIndex) in groupedMetadata" :key="metadataColIndex + 'metadataColIndex'">
                    <label>{{ metadataName }} <span class="text-danger">*</span></label>
                    <template v-if="getFieldType(metadataName) === 'number'">
                      <b-form-input type="number" v-model="inputValues[metadataName]"></b-form-input>
                    </template>
                    <template v-else-if="getFieldType(metadataName) === 'date'">
                      <b-form-datepicker v-model="inputValues[metadataName]"></b-form-datepicker>
                    </template>
                    <template v-else-if="getFieldType(metadataName) === 'boolean'">
                      <b-form-checkbox v-model="inputValues[metadataName]">{{ metadataName }}</b-form-checkbox>
                    </template>
                    <template v-else-if="getFieldType(metadataName) === 'vendor' || metadataName == 'TSP Name'">
                      <b-form-select v-model="inputValues[metadataName]" searchable>
                        <option v-for="vendor in vendors" :key="vendor.id" :value="vendor.name">{{ vendor.name }}</option>
                      </b-form-select>
                    </template>
                    <template v-else>
                      <b-form-input v-model="inputValues[metadataName]"></b-form-input>
                    </template>
                  </b-col>
                </b-row>
              </div>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button ariant="primary" squared class="ml-2" @click="searchAllDocuments">Search</b-button>
                  </div>
                  <div>
                    <b-button variant="outline-red" squared class="ml-2" @click="clearSearch">Clear</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="12">          
              <b-table striped hover
                       sort-icon-left
                       resizable
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       :per-page="tableData.resultsPerPage"
                       id="searchDocument"
                       :current-page="tableData.currentPage">
                <template #cell(index)="data">
                  {{ data.index + 1 + (currentPage - 1) * tableData.resultsPerPage }}
                </template>
                
                <template #cell(uploadDate)="data">
                  {{ formatDate(data.value) }}
                </template>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(icon)="row">
                  <img width="50" :src="url + row.item.icon"/>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openDocumentContent(row.item)" size="sm" class="btn-icon">
                      <b-icon-file-earmark></b-icon-file-earmark>
                    </b-button>
                    <b-button @click="toggleViewDocument(row.item)" size="sm" class="btn-icon">
                      <b-icon-eye></b-icon-eye>
                    </b-button>
                    <b-button v-if="canDelete" @click="toggleConfirmDelete(row.item)" size="sm" class="btn-icon">
                      <b-icon-trash></b-icon-trash>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalNoDocuments"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="searchDocument"
                    @change="handlePageChange"
                ></b-pagination>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal v-model="displayAddDocument" title="View Document"  size="xl">
      <viewDocument @toggleViewDocument="toggleViewDocument" @documentUpdated="documentUpdated"  :entryId="selectedEntryId"/>
    </b-modal>
    <b-modal v-model="displayCreateDocument" title="Create Document"  size="xl">
      <createDocument @toggleAddDocument="toggleAddDocument" @onDocumentSaved="onDocumentSaved"/>
    </b-modal>
    <b-modal v-model="displayDocumentContent" :title="displayedDocumentName"  size="xl">
      <iframe v-if="documentUrl" :src="documentUrl" style="width: 100%; height: 94vh;"></iframe>
    </b-modal>

    <b-modal v-model="displayDeleteDocument" title="Delete Document" >
      <b-row>
        <div style="width: 100%;">
          <b-row class="mt-3">
            <b-col cols="12" >
              <h4>Are you sure you want to Delete the Document?</h4>
            </b-col>
          </b-row>

          <hr class="mx-3">

          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-button variant="outline-secondary" squared class="ml-2" @click="toggleConfirmDelete">Cancel</b-button>
              <b-button variant="danger" squared class="ml-2" @click="confirmDelete">Confirm Delete</b-button>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>
<style >
.primary {
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
.modal-body {
  padding: 0px;
}

.modal-footer {
  display: none;
}
</style>
<script>

import { mapMutations} from "vuex";
import { BModal } from 'bootstrap-vue';

import warehouseApi from '../warehouseApi';
import ViewDocument from './ViewDocument.vue';
import CreateDocument from './CreateDocument.vue';
import DateRangePicker from "./DateRangePicker.vue";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "SearchDocument",
  props: {
    folderFilter: Object,
    vendors: Array
  },
  components: {
    BModal,
    ViewDocument,
    CreateDocument,
    DateRangePicker
  },
  data: () => ({
    filterRootFolder: null,
    url: "",
    documentUrl: "",
    displayedDocumentName: "",
    displayAddDocument: false,
    displayCreateDocument: false,
    displayDocumentContent: false,
    displayDeleteDocument: false,
    selectedEntryId: null,
    startDate: "",
    endDate: "",
    defaultDate: [],
    tableData: {
      resultsPerPage: 500,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'No.',
          key: 'index',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Document Type',
          key: 'documentType.name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Upload Date',
          key: 'uploadDate',
          sortable: true,
          tdClass: ''
        },  
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    totalNoDocuments: 0,
    currentPage: 1,
    documents: [],
    searchTerm: '',
    iconSelected: "",
    searchQuery: null,
    selectedFile: {
      name: "",
      type: "",
      content: ""
    },
    state: 'show',
    selectedDocumentType: null,
    selectedDocumentTypeMetadataList: [],
    inputValues: {},
    selectedMetadataList: [],
    displayMetadata: [],
    documentTypes: [],
    isDocumentDownload: false,
    documentId: null,
    canDelete: false
  }),
  beforeMount() {
    this.role = JSON.parse(localStorage.getItem("role"))
    this.canDelete = this.role.Name == 'Admin';
    this.getDocumentTypes();
    this.setBreadcrumb([
      {
        text: 'Document Warehouse'
      },
    ]);

    this.getAllDocuments();
  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    getFieldType(fieldName) {
      const result = this.selectedDocumentType.metadata.find(item => item.fieldName === fieldName);
      return result ? result.fieldType.toLowerCase() : null;
    },
    onDateSelected(dateSelected) {
      this.startDate = dateSelected.startDate;
      this.endDate = dateSelected.endDate;
      this.searchDocument();
    },
    openDocumentContent(document) {
      this.isDocumentDownload = true;
      warehouseApi.getDocumentWithContent(document.entryId)
        .then((data) => {
          this.displayDocumentContent = true;
          this.displayedDocumentName = document.name;
          this.documentUrl = `data:application/pdf;base64,${data.documentContent}`;
        })
        .catch((error) => {
          this.isDocumentDownload = false;
          console.log(error);
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getAllDocuments();
    },
    searchAllDocuments() {
      this.currentPage = 1;
      this.getAllDocuments();
    },
    getAllDocuments(){
      let request = this.getSearchCriteria();
      this.state = "loading";
      this.tableData.isLoading = true;
      warehouseApi.searchDocuments(request)
        .then((response) => {
          let documents = response.documents;
          this.totalNoDocuments = response.totalCount;
          documents.sort((a, b) => {
            const dateA = new Date(a.uploadDate);
            const dateB = new Date(b.uploadDate);
            return dateB - dateA; // Sort in descending order
          });

          this.tableData.dataSource = documents;
          const metadataValues = {};

          this.documentTypes.forEach(docType => {
              const targetFieldNames = docType.metadata.map(field => field.fieldName);
              documents.forEach(document => {
                  if (document.metadata && document.documentType && document.documentType.name === docType.name) {
                      const docTypeName = document.documentType.name;

                      if (!metadataValues[docTypeName]) {
                          metadataValues[docTypeName] = {};
                      }

                      document.metadata.forEach(field => {
                        if (targetFieldNames.includes(field.fieldName)) {
                            if (!metadataValues[docTypeName][field.fieldName]) {
                                metadataValues[docTypeName][field.fieldName] = [];
                            }

                            const valueExists = metadataValues[docTypeName][field.fieldName].some(item => item.name === field.fieldValue);

                            if (!valueExists) {
                                const uniqueID = uuidv4();
                                metadataValues[docTypeName][field.fieldName].push({id: uniqueID, name: field.fieldValue});
                            }
                        }
                    });
                  }
              });
          });

          this.$emit("metadataValues", metadataValues);

          this.documents = documents;
          this.state = "show";
          this.tableData.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.state = "show";
          this.tableData.isLoading = false;
        });
    },
    search() {
      this.tableData.dataSource = this.documents.filter((x) => 
        x.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.grouping.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.target.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.url.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    documentUpdated(){
      if (document) {
        this.selectedEntryId = document.entryId;
      } else {
        this.selectedEntryId = null;
      }

      this.displayAddDocument = !this.displayAddDocument;

      this.getAllDocuments();
    },
    confirmDelete(){
      warehouseApi.deleteDocument(this.documentId)
        .then(() => {
          this.documents = this.documents.filter(item => item.id !== this.documentId);
          this.tableData.dataSource = this.documents;
          this.toggleConfirmDelete();
          this.$toast.success('Document deleted successfully!');
        })
        .catch((error) => {
          this.$toast.success('Could not delete document!');
          console.log(error);
        });
    },
    toggleViewDocument(document) {
      if (document) {
        this.selectedEntryId = document.entryId;
      } else {
        this.selectedEntryId = null;
      }
      this.displayAddDocument = !this.displayAddDocument;
    },
    toggleConfirmDelete(document) {
      if (document) {
        this.documentId = document.id;
        this.selectedEntryId = document.entryId;
      } else {
        this.selectedEntryId = null;
        this.documentId = null;
      }
      this.displayDeleteDocument = !this.displayDeleteDocument;
    },
    toggleAddDocument() {
      this.displayCreateDocument = !this.displayCreateDocument;
    },
    formatDate(uploadDate) {
      if (!uploadDate) {
        return 'N/A';
      }

      const dateObj = new Date(uploadDate);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      const hours = String(dateObj.getHours()).padStart(2, '0');
      const minutes = String(dateObj.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    getDocumentTypes() {
      warehouseApi.getDocumentTypes()
        .then((data) => {
          this.documentTypes = data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStructuredMetadata(){
      return Object.keys(this.inputValues).map(key => ({
                    fieldName: key,
                    fieldValue: this.inputValues[key]
                  }));
    },
    validateInputValues() {
      let isValid = true;
      const inputValues = this.inputValues;

      for (const key in inputValues) {
        const value = inputValues[key];

        if (value === null || value === undefined) {
          isValid = false;
          break;
        }

        if (typeof value === 'string') {
          const trimmedValue = value.trim();
          if (trimmedValue === '') {
            isValid = false;
            break;
          }
        }
      }
      return isValid;
    },
    clearSearch(){
      this.searchQuery = null;
      this.inputValues = {};
      this.selectedDocumentTypeMetadataList = [];
      this.selectedDocumentType = null,
      this.selectedMetadataList = [],
      this.startDate = "";
      this.endDate = "";
      this.tableData.dataSource = [...this.documents];
      this.$refs.datePickerRef.clearDate();
      this.searchAllDocuments();
    },
    getSearchCriteria() {
      let fieldValues = null;

      if (this.selectedDocumentType) {
        let metadata = this.selectedDocumentType.metadata;
        fieldValues = Object.keys(this.inputValues).map(key => {
          let metadataItem = metadata.find(md => md.fieldName === key);

          return {
            Id: metadataItem ? metadataItem.id : null,
            Field: {
              Id: metadataItem ? metadataItem.id : null,
              FieldName: key,
              FieldType: metadataItem ? metadataItem.fieldType : null
            },
            Value: this.inputValues[key]
          };
        });
      }

      let searchCriteria = {};

      if (this.searchQuery != null) searchCriteria.searchQuery = this.searchQuery;
      if (fieldValues != null) searchCriteria.fieldValues = fieldValues;
      if (this.selectedDocumentType != null) searchCriteria.documentType = this.selectedDocumentType;
      if (this.startDate != null && this.startDate.trim() != "") searchCriteria.startDate = this.startDate;
      if (this.endDate != null && this.endDate.trim() != "") searchCriteria.endDate = this.endDate;
      if (this.currentPage != null) searchCriteria.pageNumber = this.currentPage;

      return searchCriteria;
    },
    searchDocument() {
      let results = [...this.documents];
      
      if(this.selectedDocumentType) {
        results = results.filter(document => document.documentType.name.toLowerCase() === this.selectedDocumentType.name.toLowerCase());
      }

      if(this.searchQuery && this.searchQuery.trim()) {
        results = results.filter(document => document.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }

      if (this.startDate && this.endDate) {
        const startDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        results = results.filter(document => {
          const uploadDate = new Date(document.uploadDate);
          uploadDate.setHours(0, 0, 0, 0);
          return startDate <= uploadDate && uploadDate <= endDate;
        });
      }

      if(this.validateInputValues()) {
        const metaData = this.getStructuredMetadata();
        results = results.filter(document => {
          return metaData.every(filter => {
            const metadataField = document.metadata.find(meta => meta.fieldName.toLowerCase().includes(filter.fieldName.toLowerCase()));
            if (metadataField && metadataField.fieldValue.toLowerCase().includes(filter.fieldValue.toLowerCase())) {
              return true;
            }
            return false;
          });
        });
      }

      this.tableData.dataSource = results;
    },
    onDocumentSaved(document){
      this.documents.unshift(document);
      this.tableData.dataSource = this.documents;
    },
    capitalize(str) {
        if (!str) return str;
        return str[0].toUpperCase() + str.substr(1);
    }
  },
  watch: {
    selectedDocumentType(){
        if(this.selectedDocumentType) {
          this.searchAllDocuments();
        }
    },
    folderFilter(item) {
      let existingFilterValues = Object.keys(this.inputValues);

      if (item.filterType === "value") {
        if(item.parent.parentName) {
          let index = this.documentTypes.findIndex(x => x.name == item.parent.parentName);
          if (index > -1) {
            this.selectedDocumentType = {...this.documentTypes[index]};
          }
        }

        let fieldType = this.capitalize(item.fieldType);
        const index = this.selectedDocumentTypeMetadataList.indexOf(fieldType);
        if (index !== -1) {
            this.selectedDocumentTypeMetadataList.splice(index, 1);
        }

        this.selectedDocumentTypeMetadataList.push(fieldType);
        this.selectedDocumentTypeMetadataList.sort()
        this.inputValues[fieldType] = item.fieldValue;

        if (this.filterRootFolder) {
          for (const inputValueKey in existingFilterValues) {
              let inputValue = existingFilterValues[inputValueKey];
              let metadataIndexToCompare = this.selectedDocumentType.metadata.findIndex(x => this.capitalize(x.fieldName) == this.capitalize(inputValue));
              if (metadataIndexToCompare == -1) {
                delete this.inputValues[inputValue];
                let inputValueMetadataIndex = this.selectedDocumentTypeMetadataList.indexOf(this.capitalize(inputValue));
                if (inputValueMetadataIndex !== -1) {
                    this.selectedDocumentTypeMetadataList.splice(inputValueMetadataIndex, 1);
                }
              }
          }
        }

        this.filterRootFolder = item.parent.parentName;
        this.searchDocument();
      } else if (item.filterType === "folder" && !item?.folder.isRootFolder) {
        if(item.folder.isRootFolder) {
          let index = this.documentTypes.findIndex(x => x.name == item.folder.name);
          if (index > -1) {
            this.selectedDocumentType = {...this.documentTypes[index]};
          }
          this.searchDocument();
        } else {
          if(!item.folder.isRootFolder) {
            let index = this.documentTypes.findIndex(x => x.name == item.folder.parentName);
            if (index > -1) {
              this.selectedDocumentType = {...this.documentTypes[index]};
            }

            if (this.filterRootFolder) {
              for (const inputValueKey in existingFilterValues) {
                  let inputValue = existingFilterValues[inputValueKey];
                  let metadataIndexToCompare = this.selectedDocumentType.metadata.findIndex(x => this.capitalize(x.fieldName) == this.capitalize(inputValue));
                  if (metadataIndexToCompare == -1) {
                    delete this.inputValues[inputValue];
                    let inputValueMetadataIndex = this.selectedDocumentTypeMetadataList.indexOf(this.capitalize(inputValue));
                    if (inputValueMetadataIndex !== -1) {
                        this.selectedDocumentTypeMetadataList.splice(inputValueMetadataIndex, 1);
                    }
                  }
              }
            }

            this.filterRootFolder = item.folder.parentName;
          }
        }
        
        let fieldType = this.capitalize(item.folder.name);
        const index = this.selectedDocumentTypeMetadataList.indexOf(fieldType);
        if (index !== -1) {
            this.selectedDocumentTypeMetadataList.splice(index, 1);
        }

        this.selectedDocumentTypeMetadataList.push(fieldType);
        this.selectedDocumentTypeMetadataList.sort()

        this.inputValues[fieldType] = item.fieldValue;
        this.searchDocument();
      } else {
        let index = this.documentTypes.findIndex(x => x.name == item.folder.name);
        if (index > -1) {
          this.selectedDocumentType = {...this.documentTypes[index]};
        }
        
        let results = [...this.documents];
        if(this.selectedDocumentType) {
          results = results.filter(document => document.documentType.name.toLowerCase() == this.selectedDocumentType.name.toLowerCase());
        }

        this.selectedDocumentTypeMetadataList = [];
        this.inputValues = {};

        this.tableData.dataSource = results;
      }
    },
    searchQuery(){
      this.searchDocument();
    },
    inputValues(){
      this.searchDocument();
    },
    selectedDocumentTypeMetadataList(selectedList) {
      const groups = [];
      let group = [];

      for (let i = 0; i < selectedList.length; i++) {
          group.push(selectedList[i]);

          if (group.length === 3) {
              groups.push(group);
              group = [];
          }
      }

      // If there are any remaining elements not grouped
      if (group.length > 0) {
          groups.push(group);
      }

      this.selectedMetadataList = groups;

      for (const key in this.inputValues) {
        if (!selectedList.includes(key)) {
          delete this.inputValues[key];
        }
      }

      this.searchDocument();
    }
  }
}
</script>

<style scoped>
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1200px !important;
    margin: 0px;
  }
}
</style>